<template>
  <el-dialog
      :title="params.title"
      :model-value="params.show"
      @close="onCacel"
      width="800px" center>
    <el-form :model="form" :rules="rules" ref="form" label-width="120px" class="ruleForm">
      <div style="display: grid; grid-template-columns: repeat(2, 50%);" >

        <el-form-item label="工单编号" prop="woCode">
          <el-input v-model.trim="form.woCode"  readonly></el-input>
        </el-form-item>

        <el-form-item label="报修类型" prop="woType">
          <el-select v-model="form.woType" placeholder="请选择报修类型" style="width: 100%">
            <el-option key="请选择" label="请选择" value=""/>
            <el-option v-for="item in global.dict.RepairType" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="报修项目" prop="repairProject">
          <el-select v-model="form.repairProject" placeholder="请选择报修项目" style="width: 100%" >
            <el-option key="请选择" label="请选择" value=""/>
            <el-option v-for="item in global.dict.RepairProject" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="报修内容" prop="woDescribe" style="grid-column: 1 / 3;">
          <el-input type="textarea" :rows="10" v-model.trim="form.woDescribe" placeholder="请输入报修内容"></el-input>
        </el-form-item>

        <el-form-item label="客户名称" prop="compName" >
          <SelectLink url="/BusinessManagement/AllList" filterable v-model="form.compName" :selected="form.compName" field="compName" @change="(val)=>changeName(val)" ></SelectLink>
        </el-form-item>

        <el-form-item label="客户担当" prop="contactPerson"  >
          <el-input :modelValue="form.contactPerson" placeholder="选择客户名称后自动填入" readonly></el-input>
        </el-form-item>

        <el-form-item label="客户地址" prop="compAddress">
          <el-input v-model.trim="form.compAddress" placeholder="选择客户名称后自动填入" readonly></el-input>
        </el-form-item>

        <el-form-item label="报修人" prop="telName">
            <el-input v-model.trim="form.telName" placeholder="请输入报修人"></el-input>
        </el-form-item>

        <el-form-item label="报修人电话" prop="telphone">
            <el-input v-model.number="form.telphone" placeholder="请输入手机号码"></el-input>
        </el-form-item>
    <br/>
        <el-form-item label="维修方式" prop="maintenanceMethod" >
          <el-select v-model="form.maintenanceMethod" placeholder="请选择维修方式" style="width: 100%" @change="changeMethods" filterable>
            <el-option key="请选择" label="请选择" value=""/>
            <el-option v-for="item in global.dict.MaintenanceMethod" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </el-form-item>
 <template v-if="form.maintenanceMethod=='远程'">
        <el-form-item label="派单给工程师" prop="woRepairEngineerName">
          <el-select v-model="form.woRepairEngineerId" placeholder="请选择派单给工程师" style="width: 100%" @change="changeEngineer" filterable>
            <el-option key="请选择" label="请选择" value=""/>
            <el-option v-for="item in engineerNameList" :key="item.id" :label="item.realNamer" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        </template>
        
        <template v-if="form.maintenanceMethod=='现场'">
        <el-form-item label="派单给工程师" prop="engineerNameList">
					<SelectLinkAllData placeholder="请选择派单给工程师" :selected="selectedArr" :options="engineerNameList" field="realNamer" @change="changeInspectors"></SelectLinkAllData>
				</el-form-item>
        </template>

<!--        <el-form :model="form" label-width="300px" class="ruleForm" >-->
<!--          <el-form-item label="指派工程师" prop="engId">-->
<!--            <SelectLink url="/WrokOrderAccept/List666" :selected="form.woRepairEngineerName" field="engName" @change="(val)=>{form.woRepairEngineerId = val.key;form.woRepairEngineerName=val.value}" ></SelectLink>-->
<!--          </el-form-item>-->
<!--          <el-form-item>-->



<!--        <el-form-item label="企业名称" prop="compName">-->
<!--         <el-input v-model.trim="form.compName" placeholder="请输入企业名称"></el-input>-->
<!--        </el-form-item>-->

<!--        <el-form-item label="企业Id" prop="compId">-->
<!--         <el-input v-model.trim="form.compId" placeholder="请输入企业Id"></el-input>-->
<!--        </el-form-item>-->

<!--        <el-form-item label="建单时间" prop="woCreateTime">-->
<!--          <el-date-picker-->
<!--              v-model="form.woCreateTime"-->
<!--              type="datetime"-->
<!--              value-format="YYYY-MM-DD HH:mm:ss"-->
<!--              placeholder="选择日期">-->
<!--          </el-date-picker>-->
<!--        </el-form-item>-->

<!--        <el-form-item label="调度员姓名" prop="woDispatcherName">-->
<!--         <el-input v-model.trim="form.woDispatcherName" placeholder="请输入调度员姓名"></el-input>-->
<!--        </el-form-item>-->

<!--        <el-form-item label="调度时间" prop="woDisTime">-->
<!--          <el-date-picker-->
<!--              v-model="form.woDisTime"-->
<!--              type="datetime"-->
<!--              value-format="YYYY-MM-DD HH:mm:ss"-->
<!--              placeholder="选择日期">-->
<!--          </el-date-picker>-->
<!--        </el-form-item>-->

<!--        <el-form-item label="维修时间" prop="woRepairTime">-->
<!--          <el-date-picker-->
<!--              v-model="form.woRepairTime"-->
<!--              type="datetime"-->
<!--              value-format="YYYY-MM-DD HH:mm:ss"-->
<!--              placeholder="选择日期">-->
<!--          </el-date-picker>-->
<!--        </el-form-item>-->



<!--        <el-form-item label="调度员ID" prop="woDisId">-->
<!--         <el-input v-model.trim="form.woDisId" placeholder="请输入调度员ID"></el-input>-->
<!--        </el-form-item>-->

<!--        <el-form-item label="维修工程师ID" prop="woRepairEngineerId">-->
<!--         <el-input v-model.trim="form.woRepairEngineerId" placeholder="请输入维修工程师ID"></el-input>-->
<!--        </el-form-item>-->

<!--        <el-form-item label="维修/诊断内容" prop="woRepairContent" style="grid-column: 1 / 3;">-->
<!--         <el-input type="textarea" :rows="10" v-model.trim="form.woRepairContent" placeholder="请输入维修/诊断内容"></el-input>-->
<!--        </el-form-item>-->

<!--        <el-form-item label="维修定价" prop="woRepairSetPrice">-->
<!--         <el-input-number v-model="form.woRepairSetPrice" :max="10" placeholder="请输入维修定价"></el-input-number>-->
<!--        </el-form-item>-->

<!--        <el-form-item label="维修记录id" prop="woRepairRecordId">-->
<!--         <el-input v-model.trim="form.woRepairRecordId" placeholder="请输入维修记录id"></el-input>-->
<!--        </el-form-item>-->

<!--        <el-form-item label="工单流转状态" prop="woStatus">-->
<!--         <el-select v-model="form.woStatus" placeholder="请选择工单流转状态" style="width: 100%">-->
<!--          <el-option key="请选择" label="请选择" value=""/>-->
<!--          <el-option v-for="item in global.dict.WorkOrderStatus" :key="item" :label="item" :value="item"></el-option>-->
<!--         </el-select>-->
<!--        </el-form-item>-->



<!--        <el-form-item label=" 离职状态" prop="woQuitStatus">-->
<!--         <el-input v-model.trim="form.woQuitStatus" placeholder="请输入 离职状态"></el-input>-->
<!--        </el-form-item>-->

<!--        <el-form-item label="关单时间" prop="woCloseTime">-->
<!--          <el-date-picker-->
<!--              v-model="form.woCloseTime"-->
<!--              type="datetime"-->
<!--              value-format="YYYY-MM-DD HH:mm:ss"-->
<!--              placeholder="选择日期">-->
<!--          </el-date-picker>-->
<!--        </el-form-item>-->

<!--        <el-form-item label="关单调度员Id" prop="woCloseDisId">-->
<!--         <el-input v-model.trim="form.woCloseDisId" placeholder="请输入关单调度员Id"></el-input>-->
<!--        </el-form-item>-->

<!--        <el-form-item label="关单调度员姓名" prop="woCloseDisName">-->
<!--         <el-input v-model.trim="form.woCloseDisName" placeholder="请输入关单调度员姓名"></el-input>-->
<!--        </el-form-item>-->

<!--        <el-form-item label="关键字搜索使用" prop="keyWord">-->
<!--         <el-input v-model.trim="form.keyWord" placeholder="请输入关键字搜索使用"></el-input>-->
<!--        </el-form-item>-->
      </div>
      <el-form-item>
        <el-button type="primary" @click="submitForm('form')" >保存</el-button>
        <el-button @click="onCacel()">返回</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>

import axios from '@/utils/axios'
import {ElMessage} from 'element-plus'
import SelectLinkAllData from "@/components/SelectLinkAllData"
import SelectLink from "@/components/SelectLink"



export default {

// 获取列表
  getTableData() {
    this.loading = true
    var that = this
    axios.get('/Api/WrokOrderAccept/GetEnglist', {
      params: Object.assign({}, this.searchData, this.pageData)
    }).then(res => {
      console.log("增加获取工程师名",res.data);
    }).catch(error => {
      that.loading = false
    })
  },
  name: "Form",
  // 继承父窗体列表页的数据
  props: {
    params: Object
  },
  data() {
    return {
      selectedArr: [],
      selectedArrAll: [],
      //派单工程师的下拉选数组
      engineerNameList: [],
      //派单工程师url
      engierUrl: '',
      // 全局变量，用于获得配置信息等
      global: window.global,

      // 更多选项
      option: {
        
      },
      // 验证选项
      rules: {
        
        compName: [
          {max: 100, message: '长度不能超过100个字符',  trigger: 'change'},
          {required: true, message: '不能为空',  trigger: 'change'}
        ],
        compId: [
          {max: 100, message: '长度不能超过100个字符',  trigger: 'change'}
        ],
        telName: [
          {max: 100, message: '长度不能超过100个字符',  trigger: 'change'},
          {required: true, message: '不能为空',  trigger: 'change'}
        ],
        telphone: [
          {pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/, message: '手机号码格式不正确',  trigger: 'change'}
        ],
        woType: [
          {max: 100, message: '长度不能超过100个字符',  trigger: 'change'},
          {required: true, message: '不能为空',  trigger: 'change'}
        ],
        repairProject: [
          {max: 100, message: '长度不能超过100个字符',  trigger: 'change'},
          {required: true, message: '不能为空',  trigger: 'change'}
        ],
        maintenanceMethod: [
          {max: 100, message: '长度不能超过100个字符',  trigger: 'change'},
        ],
        compAddress: [
          {max: 100, message: '长度不能超过100个字符',  trigger: 'change'}
        ],
        woDispatcherName: [
          {max: 100, message: '长度不能超过100个字符',  trigger: 'change'}
        ],
        woRepairEngineerName: [
          {max: 100, message: '长度不能超过100个字符',  trigger: 'change'}
        ],
        woDisId: [
          {max: 100, message: '长度不能超过100个字符',  trigger: 'change'}
        ],
        woRepairEngineerId: [
          {max: 100, message: '长度不能超过100个字符',  trigger: 'change'}
        ],
        woRepairRecordId: [
          {max: 100, message: '长度不能超过100个字符',  trigger: 'change'}
        ],
        woCode: [
          {max: 100, message: '长度不能超过100个字符',  trigger: 'change'}
        ],
        woQuitStatus: [
          {max: 100, message: '长度不能超过100个字符',  trigger: 'change'}
        ],
        woCloseDisId: [
          {max: 100, message: '长度不能超过100个字符',  trigger: 'change'}
        ],
        woCloseDisName: [
          {max: 100, message: '长度不能超过100个字符',  trigger: 'change'}
        ],
        keyWord: [
          {max: 100, message: '长度不能超过100个字符',  trigger: 'change'}
        ],
      },
    };
  },
  components: {
    SelectLink,
    SelectLinkAllData
  },
  computed: {
    // 由于子组件不能修改继承来的对象，所以重新复制一份
   form: function () {
   			this.$nextTick(()=>{
   				this.$refs['form'].clearValidate() // 只清除清除验证
   			})
       
   			return this.params.data
   		}
  },
  created() {
    // this.getTableData1();
    // console.log("0987866");
  },
  // 父组件中继承过来的事件
  emits: ["onClose","getTableData"],
  methods: {
    //选择多个现场工程师
    changeInspectors(arr) {
      console.log("arr",arr)
      this.selectedArrAll = arr;
      arr.map(item => {
         this.selectedArr.push(arr.value);
      })
     
    },
    //改变工程师
    changeEngineer(item) {
      console.log("远程选择工程师时的item",item)
    
    },
    //维修方式改变
    changeMethods(val) {
		var that = this 
      console.log("改变的维修方式",val)
      if(val == '现场') {
        this.selectedArr = []
      }
      console.log("this.form.maintenanceMethod",this.form.maintenanceMethod)
      axios.get('/WrokOrderAccept/MethodCorrEngList?currentState='+val).then((res) => {
        console.log("改变的维修方式返回的结果", res)
		if(res.data.list && res.data.list.length>0){
			that.engineerNameList = res.data.list;
			if(that.form.woRepairEngineerId != undefined){
				that.form.woRepairEngineerId = ''
			}
		}else{
			that.selectedArrAll = []
      that.selectedArr = []
			if(that.form.woRepairEngineerId != undefined){
				that.form.woRepairEngineerId = ''
			}
		}
        // this.engineerNameList = res.data;
		 console.log("this.engineerNameList", this.engineerNameList)
         // this.form.woRepairEngineerId = res.data.list.id
         // this.form.woRepairEngineerName = res.data.list.realNamer
        // val 现场/远程
      })
    },
    //客户名称改变值
    changeName(val) {
      console.log("客户名称改变值",val);
      this.compName = val.key;
      axios.get('/BusinessManagement/Info?id='+val.key).then((res) => {
        console.log("客户名称改变值返回的结果",res)
          this.form.contactPerson = res.data.contactPerson
        this.form.compAddress = res.data.compAddress
        this.form.compId = val.key
      })

    },
    // 提交事件
    submitForm(formName) {
      var that = this
      that.form.engineerNameList = that.selectedArrAll;
      console.log("that.form",that.form)

      this.$refs[formName].validate((valid) => {

        if (valid) {

          if (this.params.type == 'add') {
            axios.post('/WrokOrderAccept/CreateWorkOder', this.form).then(() => {
              console.log("this.form",this.form);
              ElMessage.success('添加成功')
              that.$emit('onClose')
              that.$emit('getTableData')
            })
          } else {
            axios.post('/WrokOrderAccept/ModifyWorkOder?id='+this.form.id, this.form).then(() => {
              ElMessage.success('修改成功')
              that.$emit('onClose')
              that.$emit('getTableData')
            })
          }
        }
      })
    },
    // 返回事件
    onCacel() {
      this.selectedArrAll = [];
      this.selectedArr = [];
      console.log("this.selectedArrAll",this.selectedArrAll)
      console.log("this.selectedArr",this.selectedArr)
      this.$emit('onClose')
    }



    },
}
</script>