<template>
  <el-select v-model="selectValue" multiple placeholder="请选择"  @change="changeValue" >
    <el-option
        v-for="item in optionsArr"
        :key="item.key"
        :label="item.label"
        :value="item.value">
    </el-option>
  </el-select>
</template>

<script>
import {reactive, ref, watch} from "vue";
import axios from "@/utils/axios";
export default ({
  props: {
    selected: String,
    field: String,
    options: Array
  },
  emits: ['change'],
  setup(props, context) {

    const global = window.global

    // 监听选中值的变化
    console.log("props.selected4444",props.selected)
    let selectValue = ref(props.selected)
    console.log("传递数据selectValue333",selectValue.value)
    watch(() =>props.selected, (count, prevCount) => {
      console.log("count",count)
      console.log("传递数据selectValue444",selectValue)
      selectValue = count
    })

     // 加载可选项
    let optionsArr = reactive([])
    watch(() =>props.options, (arr, prevCount) => {
      console.log("传递的options",arr)
      arr.map(item => {
        optionsArr.push({
            key: item.id,
            value: item[props.field],
            label: item[props.field],
        })
      })
    })

     const optionsValue = []
    const changeValue = (value)=>{
        optionsValue.splice(0);
       value.map(item1 =>{
         optionsArr.map(item => {
        // eslint-disable-next-line no-empty
        if (item.value == item1){
           optionsValue.push(item)
        }
        })
      })
	    console.log("optionsValue111,",optionsValue)
      context.emit("change",optionsValue)
    }
    return {
      global,optionsArr,selectValue,changeValue
    
    }
  },
})
</script>
