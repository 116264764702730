<template>
  <el-select v-model="selectValue" placeholder="请选择" @change="changeValue" style="width: 100%">
    <el-option key="请选择" label="请选择" value=""/>
    <el-option
        v-for="item in options"
        :key="item.key"
        :label="item.label"
        :value="item.value">
    </el-option>
  </el-select>
</template>

<script>
import {reactive, ref, watch} from "vue";
import axios from "@/utils/axios";
export default ({
  props: {
    url: String,
    selected: String,
    field: String,
	newData: Object
  },
  emits: ['change'],
  setup(props, context) {

    const global = window.global
   
       // 监听选中值的变化
       let selectValue = ref(props.selected)
       watch(() =>props.selected, (count, prevCount) => {
         selectValue.value = count
       })
	   
	   let objChange = ref(props.newData)
		if(props.newData == undefined) {
			objChange = {}
		}else {
			objChange = props.newData
		}
		
		let options = reactive([])
	   watch(() =>props.newData, (count, prevCount) => {
	   		 console.log("count111111",count)
	   		 objChange = count
			 options.length = 0
	   		 axios.get(props.url, {params: objChange}).then((data) => {
	   		   console.log("selectLink返回的data数据",data)
	   		   if(data.data.list && data.data.list.length > 0) {
	   			   data.data.list.map(item => {
	   				 options.push({
	   				   value: item[props.field],
	   				   label: item[props.field],
	   				   key: item.id,
	   				 })
	   			   })
	   		   }
			   console.log("options565656",options)
	   		 })

	   })

	   	console.log("监听选中值的变化返回的objChange",objChange)
	   // 加载可选项
	   // let options = reactive([])
	   
	   axios.get(props.url, {params: objChange}).then((data) => {
	   		   console.log("selectLink返回的data数据",data)
	   		   if(data.data.list && data.data.list.length > 0 ) {
	   			   data.data.list.map(item => {
	   			     options.push({
	   			       value: item[props.field],
	   			       label: item[props.field],
	   			       key: item.id,
	   			     })
	   			   })
	   			   
	   		   }
	   })
   
       const changeValue = (value)=>{
		   
         options.map(item => {
           // eslint-disable-next-line no-empty
   		if(value === '') {
   			item = {
             value: "",
             label: "",
             key: "",
   			}
   			context.emit("change",item)
   		}
           if (item.value == value){
             context.emit("change",item)
           }
         })
       }
	   
	   return {
		 global,options,selectValue,changeValue
	   }
	   

  },
})
</script>
